@import 'src/utils/utils';

.search {
  @include zero;
  display: grid;
  margin-top: 30px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 25px;
  row-gap: 15px;

  @media(min-width: $lg) {
    @include box;
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    grid-template-columns: 1fr 170px;
    column-gap: 30px;
    margin-bottom: 50px;
    row-gap: 50px;
  }

  h1 {
    @include zero;
    @include Head-22-bold;
    color: $Main-1;

    @media(min-width: $xxl) {
      @include Head-35-bold;
    }

    span {
      color: $Corporate-2;
    }
  }

  .button {
    justify-self: flex-start;

    @media(min-width: $lg) {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  &__count {
    @include zero;
    @include Text-14-reg;
    color: $Main-1;

    @media(min-width: $xxl) {
      margin-top: 18px;
    }
  }
}